import React, { useEffect, useState } from 'react';
import './Navibar.css'
import logo from './RezGlobal.png'
import logo2 from './caledon.png'
import { IoLogOut } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import AccountPopover from './AccountPopover';
import {BrowserRouter as Router, Link} from 'react-router-dom';


export default function NavibarCollasped({setLoginSuccess}) {

  const navigate = useNavigate();
  const [deUser, setDeUser] = useState("0")
  const loggedInUser = sessionStorage.getItem("user_token");
  const foundUser = JSON.parse(loggedInUser);

  const handleLogout = () => {
    
    setLoginSuccess(false)
    navigate("/", { replace: true });
    sessionStorage.clear();
  }
  useEffect(() => {
    if (foundUser.account_type._id == '767610') {
      setDeUser("1")
    }
  })
  return (
    <nav className="navbar navibar">
      <div className="container-fluid ">

        <div className="row" style={{ width: '100%', marginLeft: '-3px' }}>
          <div className="col-auto">
          <Link to="/">  
          {/* {deUser === "1"?
            <img src={logo2} alt="" className='logo' /> 
            :
            <img src={logo} alt="" className='logo' /> 
          } */}
          </Link>
          </div>

          <div className="col d-flex justify-content-end">
            {/* <IoLogOut style={{ marginTop: 5, fontSize: 35, marginRight: 17 }} onClick={() => { handleLogout()}}/> */}
            <AccountPopover setLoginSuccess={setLoginSuccess}/>
          </div>
        </div>

      </div>
    </nav>
  )
}
