import React, { useState, useEffect, useRef } from 'react'; // Added useState for managing form state
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import axios from 'axios';
import { baseURL } from '../backend_url';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { Alert } from '@mui/material';
import { jsPDF } from 'jspdf';
import Modal from 'react-bootstrap/Modal'
import Swal from "sweetalert2";
import moment from "moment";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function Reservation({ accessType }) {
    const [agreementNumber, setAgreementNumber] = useState("");
    const [vinDetail, setVinDetail] = useState([{}]);
    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const initialFormData = {
        reservation: '',
        contract: '',
        driver: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        homePhone: '',
        mobilePhone: '',
        workPhone: '',
        licenseNumber: '',
        licenseState: '',
        licenseExpiration: '',
        dob: '',
        email: '',
        locationIn: '',
        locationOut: '',
        dateOut: '',
        dateIn: '',
        rentalDays: '',
        lorAvg: '',
        timeAndKilometersOnly: '',
        discount: '',
        timeKilometersWithDiscount: '',
        rateCharge: '',
        kilometersCharge: '',
        lateCharge: '',
        fuelCharge: '',
        prepaidFuel: '',
        commerceCityTax: '',
        stateTax: '',
        airportConcession: '',
        coRoadSafetyProgram: '',
        vehicleLicenseFee: '',
        transportationFee: '',
        energySurcharge: '',
        surcharge: '',
        interest: '',
        batchOther: '',
        fuelCharges09: '',
        gps121: '',
        adminFee128: '',
        cleanupCharge19: '',
        downtime20: '',
        upgrade21: '',
        deductible23: '',
        additionalDriverAdd: '',
        borderCrossingFeeBCF: '',
        cdw3000: '',
        cdw3500: '',
        cdw4000: '',
        cdw3000Pickup: '',
        cdw3000Cargo: '',
        energySurchargeCO: '',
        coloradoRoadSafetyProgramFee: '',
        transportationFeeCO: '',
        vehicleLicensingFeeCO: '',
        childSeat: '',
        cdw4000Commercial: '',
        damage: '',
        detailFee: '',
        pickUpDropOffFee: '',
        governmentAdministrativeRateSupplement: '',
        gps: '',
        tollTagIPASS: '',
        tollTagPP: '',
        lostKeys: '',
        sli: '',
        smokeOdorFee: '',
        outOfHoursFee: '',
        oneWayFee: '',
        outOfState: '',
        personalAccidentInsurance: '',
        practicalAssistancePack: '',
        prepaidGasTank: '',
        pickupFeeAfterHours: '',
        prepaidAdjustment: '',
        roadSideAssistance: '',
        stateProvinceCrossingFee: '',
        supplementalLiabilityInsurance: '',
        tireAndWindshieldCoverage: '',
        towingCharges: '',
        ticketViolation: '',
        tollPass: '',
        tollViolation: '',
        tireGlassDamageWaiver: '',
        underAgeFee: '',
        extraKmsUnlimited: '',
        upgrade: '',
        windshieldChips: '',
        additionalDriverXDRV: '',
        underageFeeCharge: '',
        totalSelectedCharges: '',
        totalOtherCharges: '',
        totalCharges: '',
        dailyDollarAverage: '',
        adjustment: '',
        assignedUnit: '',
        assignedUnitVin: '',
        assignedUnitClass: '',
        assignedUnitKilometersOut : '',
        assignedUnitKilometersIn : '',
        assignedUnitFuelOut : '',
        assignedUnitFuelIn : ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [selectedCar, setSelectedCar] = useState({ Class: '', VIN: '' });
    const [locationDetails] = useState([{ key: 'DEN1', value: 'DEN1'}]);
    const [locationIn, setLocationIn] = useState("");
    const [locationOut, setLocationOut] = useState("");
    const [exportData, setExportData] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const fileRef = useRef();

    const handleExcelUpload1 = async (e) => {

        const myFile = e.target.files[0];

        // Read the excel sheet
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetDataInJSON = XLSX.utils.sheet_to_json(sheet);

            const extractedData = sheetDataInJSON.map((row) => {
            if (!row["Contract"]) return false;
            return {
                "_id": row["Reservation"] || "",
                "Location": row["Location"] || "",
"Reservation": row["Reservation"] || "",
"Contract": row["Contract"] || "",
"Actual Contract": row["Actual Contract"] || "",
"Driver": row["Driver"] || "",
"Date Out": row["Date Out"] || "",
"Date In": row["Date In"] || "",
"Rental Days": row["Rental Days"] || "",
"Time & Kilometers Only": row["Time & Kilometers Only"] || "",
"Discount": row["Discount"] || "",
"Time & Kilometers": row["Time & Kilometers"] || "",
"Rate Charge": row["Rate Charge"] || "",
"Kilometers Charge": row["Kilometers Charge"] || "",
"Late Charge": row["Late Charge"] || "",
"Fuel Charge": row["Fuel Charge"] || "",
"Prepaid Fuel": row["Prepaid Fuel"] || "",
"COMMERCE CITY TAX": row["COMMERCE CITY TAX"] || "",
"STATE TAX": row["STATE TAX"] || "",
"AIRPORT CONCESSION": row["AIRPORT CONCESSION"] || "",
"CO ROAD SAFETY PROGR": row["CO ROAD SAFETY PROGR"] || "",
"VEHICLE LICENSE FEE": row["VEHICLE LICENSE FEE"] || "",
"TRANSPORTATION FEE": row["TRANSPORTATION FEE"] || "",
"ENERGY SURCHARGE": row["ENERGY SURCHARGE"] || "",
"Surcharge": row["Surcharge"] || "",
"Interest": row["Interest"] || "",
"Batch Other": row["Batch Other"] || "",
"FUEL CHARGES - 09": row["FUEL CHARGES - 09"] || "",
"GPS - 121": row["GPS - 121"] || "",
"ADMIN FEE - 128": row["ADMIN FEE - 128"] || "",
"CLEANUP CHARGE - 19": row["CLEANUP CHARGE - 19"] || "",
"DOWNTIME - 20": row["DOWNTIME - 20"] || "",
"UPGRADE - 21": row["UPGRADE - 21"] || "",
"DEDUCTIBLE - 23": row["DEDUCTIBLE - 23"] || "",
"ADDITIONAL DRIVER - ADD": row["ADDITIONAL DRIVER - ADD"] || "",
"3000 DED. CDW ,CCAR,ICAR,SCAR,FCAR,PPAR - CDW": row["3000 DED. CDW ,CCAR,ICAR,SCAR,FCAR,PPAR - CDW"] || "",
"3500 DEDUCTIBLE CDW CFAR IFAR SFAR XFAR - CDW2": row["3500 DEDUCTIBLE CDW CFAR IFAR SFAR XFAR - CDW2"] || "",
"4000 DEDUCTIBLE CDW FFAR, MVAR - CDW3": row["4000 DEDUCTIBLE CDW FFAR, MVAR - CDW3"] || "",
"3000 DEDUCTIBLE CDW PKUP TRK/ PANEL VAN - CDW4": row["3000 DEDUCTIBLE CDW PKUP TRK/ PANEL VAN - CDW4"] || "",
"3000 DEDUCTIBLE CDW - PICKUP TRK, CARGO - CDWL": row["3000 DEDUCTIBLE CDW - PICKUP TRK, CARGO - CDWL"] || "",
"ENERGY SURCHARGE - CO-ES": row["ENERGY SURCHARGE - CO-ES"] || "",
"COLORADO ROAD SAFETY PROGRAM FEE - CO-RSPF": row["COLORADO ROAD SAFETY PROGRAM FEE - CO-RSPF"] || "",
"TRANSPORTATION FEE - CO-TF": row["TRANSPORTATION FEE - CO-TF"] || "",
"VEHICLE LICENSING FEE - CO-VLF": row["VEHICLE LICENSING FEE - CO-VLF"] || "",
"CHILD SEAT - CS": row["CHILD SEAT - CS"] || "",
"4000 DEDUCTIBLE CDW- COMMERCIAL - CUBE": row["4000 DEDUCTIBLE CDW- COMMERCIAL - CUBE"] || "",
"DAMAGE - DAMAGE": row["DAMAGE - DAMAGE"] || "",
"DETAIL FEE - DETAIL FEE": row["DETAIL FEE - DETAIL FEE"] || "",
"PICK UP / DROP OFF FEE - DROP": row["PICK UP / DROP OFF FEE - DROP"] || "",
"GOVERNMENT ADMINISTRATIVE RATE SUPPLEMEN - GARS": row["GOVERNMENT ADMINISTRATIVE RATE SUPPLEMEN - GARS"] || "",
"GPS - GPS": row["GPS - GPS"] || "",
"TOLL TAG - IPASS": row["TOLL TAG - IPASS"] || "",
"TOLL TAG - PP - IPASS-PP": row["TOLL TAG - PP - IPASS-PP"] || "",
"LOST KEYS - KEYS": row["LOST KEYS - KEYS"] || "",
"SLI - LINS": row["SLI - LINS"] || "",
"SMOKE/ ODOR FEE - ODOR": row["SMOKE/ ODOR FEE - ODOR"] || "",
"OUT OF HOURS FEE - OHF": row["OUT OF HOURS FEE - OHF"] || "",
"ONE WAY FEE - ONE WAY FEE": row["ONE WAY FEE - ONE WAY FEE"] || "",
"OUT OF STATE - OOS": row["OUT OF STATE - OOS"] || "",
"PERSONAL ACCIDENT INSURANCE - PAI": row["PERSONAL ACCIDENT INSURANCE - PAI"] || "",
"PRACTICAL ASSISTANCE PACK - PAP": row["PRACTICAL ASSISTANCE PACK - PAP"] || "",
"PREPAID GAS TANK - PGT": row["PREPAID GAS TANK - PGT"] || "",
"PICKUP FEE & AFTER HOURS DROP - PICKUP FEE": row["PICKUP FEE & AFTER HOURS DROP - PICKUP FEE"] || "",
"PREPAID ADJUSTMENT - PRE": row["PREPAID ADJUSTMENT - PRE"] || "",
"ROAD SIDE ASSISTANCE - R S A": row["ROAD SIDE ASSISTANCE - R S A"] || "",
"SUPPLEMENTAL LIABILITY INSURANCE - SLI": row["SUPPLEMENTAL LIABILITY INSURANCE - SLI"] || "",
"TIRE AND WINSHIELD COVERAGE - T&W": row["TIRE AND WINSHIELD COVERAGE - T&W"] || "",
"TOWING CHARGES - TC": row["TOWING CHARGES - TC"] || "",
"TICKET VIOLATION - TKTS": row["TICKET VIOLATION - TKTS"] || "",
"TOLL PASS - TOLLPASS": row["TOLL PASS - TOLLPASS"] || "",
"TOLL VIOLATION - TOLLV": row["TOLL VIOLATION - TOLLV"] || "",
"TIRE & GLASS DAMAGE WAIVER - TRW": row["TIRE & GLASS DAMAGE WAIVER - TRW"] || "",
"UNDER AGE FEE - UND AGE": row["UNDER AGE FEE - UND AGE"] || "",
"EXTRA KMS - UNLTD": row["EXTRA KMS - UNLTD"] || "",
"UPGRADE - UPGRADE": row["UPGRADE - UPGRADE"] || "",
"WINDSHIELD/ CHIPS - WINDSHIELD": row["WINDSHIELD/ CHIPS - WINDSHIELD"] || "",
"ADDITIONAL DRIVER - X-DRV": row["ADDITIONAL DRIVER - X-DRV"] || "",
"UNDERAGE FEE CHARGE - X-FEE": row["UNDERAGE FEE CHARGE - X-FEE"] || "",
"Total Selected Charges": row["Total Selected Charges"] || "",
"Total Other Charges": row["Total Other Charges"] || "",
"Total Charges": row["Total Charges"] || "",
"Daily Dollar Average": row["Daily Dollar Average"] || "",
"Adjustment": row["Adjustment"] || ""
            };
            });

            Swal.fire({
            title: 'Do you really want to upload this ?',
            icon: "question",
            showCancelButton: true,
            confirmButtonText: 'Yes',

            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                setLoading(true);
                // Handle further processing or API calls with extracted data
                axios.post(baseURL + "/upload_ra_reservation", extractedData)
                .then((res) => {

                    const status = res.data.status
                    const existing_items = res.data.existing_items
                    if(status === 'success'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Uploaded Successfully !!'
                        })
                    }
                    else if(status === 'existing'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Uploaded Successfully !!',
                        text: `These Contract IDs are already in DB: ${existing_items.join(', ')}`
                        })
                    }
                    
                }).catch((e) => {
                    setLoading(false);
                    Swal.fire({
                    icon: 'error',
                    title: 'Incorrect excel format or Incorrect data !!',
                    text:'Please correct you excel format and data then try again.'
                    })
                })
            }
            })

            fileRef.current.value = null;
        
        };
        reader.readAsBinaryString(myFile);
    };
    const handleExcelUpload2 = async (e) => {

        const myFile = e.target.files[0];

        // Read the excel sheet
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetDataInJSON = XLSX.utils.sheet_to_json(sheet);

            const extractedData = sheetDataInJSON.map((row) => {
            if (!row["Confirmation Number"]) return false;
            console.log(row["DOB"])
            return {
                "Confirmation Number": row["Confirmation Number"] || "",
                "Street 1": row["Street 1"] || "",
                "City": row["City"] || "",
                "State": row["State"] || "",
                "Country": row["Country"] || "",
                "Postal Code": row["Postal Code"] || "",
                "Home Phone": row["Home Phone"] || "",
                "License Number": row["License Number"] || "",
                "License State": row["License State"] || "",
                "License Expiration": row["License Expiration"] || "",
                "DOB": row["DOB"] || "",
                "E-Mail": row["E-Mail"] || ""
            };
            });

            Swal.fire({
            title: 'Do you really want to upload this ?',
            icon: "question",
            showCancelButton: true,
            confirmButtonText: 'Yes',

            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                setLoading(true);
                // Handle further processing or API calls with extracted data
                axios.post(baseURL + "/upload_ra_reservation2", extractedData)
                .then((res) => {

                    const status = res.data.status
                    const existing_items = res.data.existing_items
                    if(status === 'success'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Uploaded Successfully !!'
                        })
                    }
                    else if(status === 'existing'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Uploaded Successfully !!',
                        text: `These Contract IDs are already in DB: ${existing_items.join(', ')}`
                        })
                    }
                    
                }).catch((e) => {
                    setLoading(false);
                    Swal.fire({
                    icon: 'error',
                    title: 'Incorrect excel format or Incorrect data !!',
                    text:'Please correct you excel format and data then try again.'
                    })
                })
            }
            })

            fileRef.current.value = null;
        
        };
        reader.readAsBinaryString(myFile);
    };
    const handleExcelUpload = async (e) => {

        const myFile = e.target.files[0];

        // Read the excel sheet
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetDataInJSON = XLSX.utils.sheet_to_json(sheet);

            const extractedData = sheetDataInJSON.map((row) => {
            if (!row["Routes_Reservation_No"]) return false;
            console.log(row["Routes_Reservation_No"])
            return {
                "Routes_Reservation_No":row["Routes_Reservation_No"]||"",
                "Status_Valid_Cancelled_No_Show":row["Status_Valid_Cancelled_No_Show"]||"",
                "DropOff_Date_Time":row["DropOff_Date_Time"]||"",
                "Cancelled_Date_Time":row["Cancelled_Date_Time"]||"",
                "Email_ID":row["Email_ID"]||"",
                "Phone_No":row["Phone_No"]||"",
                "Base_Price_of_car":row["Base_Price_of_car"]||"",
                "Total_Price_of_car":row["Total_Price_of_car"]||"",
                "Applicable_Rate":row["Applicable_Rate"]||"",
                "Tax_on_Car_Price_Value":row["Tax_on_Car_Price_Value"]||"",
                "Tax_on_Car_Price_Details_Breakup":row["Tax_on_Car_Price_Details_Breakup"]||"",
                "Total_Price_Extras":row["Total_Price_Extras"]||"0",
                "Extras_Base_Value":row["Extras_Base_Value"]||"0",
                "Tax_on_Extras":row["Tax_on_Extras"]||"0",
                "Extras_Details":row["Extras_Details"]||"0",
                "Final_Price":row["Final_Price"]||"0",
                "length_of_rental":row["length_of_rental"]||"0",
            };
            });

            Swal.fire({
            title: 'Do you really want to upload this ?',
            icon: "question",
            showCancelButton: true,
            confirmButtonText: 'Yes',

            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                setLoading(true);
                // Handle further processing or API calls with extracted data
                axios.post(baseURL + "/upload_ra_reservation2", extractedData)
                .then((res) => {

                    const status = res.data.status
                    const existing_items = res.data.existing_items
                    if(status === 'success'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Uploaded Successfully !!'
                        })
                    }
                    else if(status === 'existing'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Uploaded Successfully !!',
                        text: `These Contract IDs are already in DB: ${existing_items.join(', ')}`
                        })
                    }
                    
                }).catch((e) => {
                    setLoading(false);
                    Swal.fire({
                    icon: 'error',
                    title: 'Incorrect excel format or Incorrect data !!',
                    text:'Please correct you excel format and data then try again.'
                    })
                })
            }
            })

            fileRef.current.value = null;
        
        };
        reader.readAsBinaryString(myFile);
    };
    const handleFetch = (e) => {
        setFormData(initialFormData);
        if (agreementNumber === '') {
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a agreement number to proceed!')
        }else{
            setValidationMsg('')
            axios.post(baseURL + "/DEN1_fetch_console_get_reservation", {
                'agreementNumber': agreementNumber.toString().trim()
            }).then((res) => {
                if (res.data === null) {
                    setLoading(false)
                    window.scrollTo(0, 0);
                    setValidationMsg('Agreement number is invalid.')
                    return;
                }else if(res.data._id != null){
                    res.data.dateOut = new Date(res.data.dateOut).toISOString().slice(0, 16);
                    res.data.dateIn = new Date(res.data.dateIn).toISOString().slice(0, 16);
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        ...res.data
                    }));
                    setLocationIn(res.data.locationIn);
                    setLocationOut(res.data.locationOut);
                }else{
                    setLoading(false)
                    window.scrollTo(0, 0);
                    setValidationMsg('Something went wrong!.')
                    return;
                }
            })
        }
    }
    const handleUnitChange = (e) => {
        const selectedUnit = e.target.value;
        const car = vinDetail.find(item => item.Unit === selectedUnit);
        if (car) {
            setSelectedCar(car);
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        } else {
            setSelectedCar({ Class: '', VIN: '' });
        }
    };
    const handleUnitChange2 = (e) => {
        if (e.target.name === 'locationIn') {
            setLocationIn(e.target.value);
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
        if (e.target.name === 'locationOut') {
            setLocationOut(e.target.value);
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
    };

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    // Handle new form
    const handleNewForm = (e) => {
        setFormData(initialFormData);
        setAgreementNumber("");
        setLocationIn("");
        setLocationOut("");
        setSuccessMsg("");
        setValidationMsg("");
    };
    useEffect(() => {
        axios.get(baseURL + "/get_VIN_DEN01")
        .then((res) => {
            setVinDetail(res.data);
        });
    },[]);
    const validationSchema = Yup.object().shape({
       
    })
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, formState: { errors } } = useForm(formOptions);

    const submitHandler = () => {
        setSuccessMsg("");
        setLoading(true);
        setValidationMsg("");
        if(formData["reservation"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a reservation to proceed!')
        }else if(formData["contract"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a contract(RA) to proceed!')
        }else if(formData["driver"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a driver to proceed!')
        }else if(formData["street1"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a street1 to proceed!')
        }else if(formData["city"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a city to proceed!')
        }else if(formData["country"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a country to proceed!')
        }else if(formData["postalCode"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a postal code to proceed!')
        }else if(formData["mobilePhone"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a mobile phone to proceed!')
        }else if(formData["licenseNumber"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a license number to proceed!')
        }else if(formData["dob"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a dob to proceed!')
        }else if(formData["email"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a email to proceed!')
        }else if(locationIn === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a location in to proceed!')
        }else if(locationOut === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a location out to proceed!')
        }else if(formData["dateOut"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a date Out to proceed!')
        }else if(formData["dateIn"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a date In to proceed!')
        }else if(formData["rentalDays"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a rental days to proceed!')
        }else if(formData["rateCharge"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a rate charge to proceed!')
        }else if(formData["stateTax"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a state tax to proceed!')
        }else if(formData["airportConcession"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a airport concession to proceed!')
        }else if(formData["coRoadSafetyProgram"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a co Road Safety Program to proceed!')
        }else if(formData["vehicleLicenseFee"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a vehicleLicense fee to proceed!')
        }else if(formData["transportationFee"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a transportation fee to proceed!')
        }else if(formData["energySurcharge"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a energy surcharge to proceed!')
        }else if(formData["surcharge"] === ""){
            setLoading(false)
            window.scrollTo(0, 0);
            setValidationMsg('Please enter a surcharge to proceed!')
        }else {
            fetch(baseURL + '/save_den1_form', {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(formData)
            })
            .then(resp => resp.json())
            .then(data => {
                setLoading(false)
                setSuccessMsg("Reservation updated successfully.")

                window.scrollTo(0, 0);
            })
            .catch(error => console.log(error))
        }
    }
    const generatePDF = () => {
        const doc = new jsPDF();

        // Add content to the PDF
        doc.text('Form Data Export', 20, 20);
        doc.text(`Name: ${formData.name}`, 20, 30);
        doc.text(`Email: ${formData.email}`, 20, 40);
        doc.text(`Message: ${formData.message}`, 20, 50);

        // Save the generated PDF
        doc.save('formData.pdf');
    };
    const handleFromDateChange = (event) => {
        const selectedDate = event.target.value;
        setFromDate(selectedDate);  // this will set the date in string format (YYYY-MM-DD)
    };
    const handleToDateChange = (event) => {
        const selectedDate = event.target.value;
        setToDate(selectedDate);  // this will set the date in string format (YYYY-MM-DD)
    };
    const handleExportData = (e) => {
        if (fromDate === "") {
            Swal.fire("Please select From Date to proceed ahead");
            return;
        }
        if (toDate === "") {
            Swal.fire("Please select To Date to proceed ahead");
            return;
        }
        axios.post(baseURL + "/get_DEN1_export", {
            from_date: fromDate,
            to_date: toDate,
        }).then((res) => {
            if(res.data.length === 0){
                Swal.fire("No Data found!");
                return;
            }else if(res.data.length > 0){
                let csvData = res.data;
                const workbook = XLSX.utils.book_new();

                // Convert the data into a worksheet
                const worksheet = XLSX.utils.json_to_sheet(csvData);

                // Append the worksheet to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

                // Generate a buffer for the workbook
                const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

                // Save the workbook
                const blob = new Blob([workbookOut], { type: 'application/octet-stream' });
                saveAs(blob, 'Reservations.xlsx');
            }else{
                Swal.fire("No Data found!");
                return;
            }
        }).catch((e) => {});
    }
    return (
        <div className='container'>
            <Modal size="lg" show={exportData} onHide={() => setExportData(false)} aria-labelledby="example-modal-sizes-title-lg" dialogClassName="modal-60w" centered >
                <Modal.Header closeButton style={{ backgroundColor: '#a7bceb', color: 'black' }} >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Export to excel
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <form >
                        <div className="row pt-2 mx-2">
                            <div className="col-auto mb-3">
                                <label htmlFor="from_date" className="form-label" style={{ marginTop: 5 }}>
                                    <b>From Date</b>
                                </label>
                            </div>
                            <div className="col-md-3 mb-3">
                                <input type="date" onChange={handleFromDateChange}
                                value={fromDate ? moment(fromDate).format('YYYY-MM-DD') : ''}
                                name="from_date" className='form-control' placeholder="From Date" />
                            </div>
                            <div className="col-auto mb-3">
                                <label htmlFor="to_date" className="form-label" style={{ marginTop: 5 }}>
                                    <b>To Date</b>
                                </label>
                            </div>
                            <div className="col-md-3 mb-3">
                                <input type="date" onChange={handleToDateChange}
                                value={toDate ? moment(toDate).format('YYYY-MM-DD') : ''}
                                name="to_date" className='form-control' placeholder="From Date" />
                            </div>
                            <div className="col-md-3 mb-3">
                                <button type="button" className="btn btn-success" onClick={(e) => handleExportData()} >Export</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <div className="row mt-2">
                <div className="col-md-2 mb-3">
                    <button type="button" className="btn btn-success" onClick={handleNewForm} >
                    + Add New
                    </button>
                </div>
                <div className="col-md-5 mb-3">
                    <button type="button" className="btn btn-success" onClick={() => setExportData(true)} >
                    Export Excel
                    </button>
                </div>
                <div className="col-md-5 mb-3 d-flex justify-content-end">
                    <input
                        ref={fileRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleExcelUpload}
                    />
                    <Button
                        disabled={accessType == "View"}
                        variant="contained"
                        color="secondary"
                        startIcon={<CloudUploadIcon />}
                        onClick={() => fileRef.current.click()}
                    >
                        Upload Excel
                    </Button>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-2 mb-3">
                    <label htmlFor="reservationNumber" className="form-label">
                        Enter Agreement Number
                    </label>
                </div>
                <div className="col-md-4 mb-3">
                    <input
                        type="text"
                        className="form-control"
                        value={agreementNumber}
                        onChange={(e) => setAgreementNumber(e.target.value)}
                        id="reservationNumber"
                    />
                </div>
                <div className="col-md-1 mb-3">
                    <button type="button" className="btn btn-success" onClick={(e) => { handleFetch(e); }} >
                    Fetch
                    </button>
                </div>
            </div>
            {/* Reservation Details Section */}
            <form className="row g-3" onSubmit={handleSubmit(submitHandler)}>
            {validationMsg ?
              (
                <Alert severity="error">{validationMsg}</Alert>
              ) : ("")
            }
            {
                successMsg ?
                    (
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            {successMsg}
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                        </div>
                    )
                    : ""
            }
            <div className='row mb-3'>
                <h3>Reservation Details</h3>
                <div className='col-12'>
                <div className='form-group row pt-2'>
                    <label htmlFor="reservation" className='col-sm-1 col-form-label'>Reservation*</label>
                    <div className='col-sm-3'>
                    <input type="text" name="reservation" className='form-control' placeholder="Reservation" onChange={handleChange} value={formData["reservation"]} />
                    </div>
                    <label htmlFor="contract" className='col-sm-1 col-form-label'>Contract (RA)*</label>
                    <div className='col-sm-3'>
                    <input type="text" name="contract" className='form-control' placeholder="Contract (RA)" onChange={handleChange} value={formData["contract"]} />
                    </div>
                    <label htmlFor="driver" className='col-sm-1 col-form-label'>Driver*</label>
                    <div className='col-sm-3'>
                    <input type="text" name="driver" className='form-control' placeholder="Driver" onChange={handleChange} value={formData["driver"]} />
                    </div>
                </div>
                </div>
            </div>
            {/* Customer Details Section */}
            <div className='row mt-3'>
                <h3>Customer Details</h3>
                <div className='col-12'>
                {['street1', 'street2', 'city*', 'state', 'country*', 'postalCode*', 'homePhone', 'mobilePhone*', 'workPhone', 'licenseNumber*', 'licenseState', 'licenseExpiration*', 'dob*', 'email*'].map((field, index) => (
                    <div className='form-group row pt-2' key={index}>
                    <label htmlFor={field} className='col-sm-2 col-form-label'>{field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                    <div className='col-sm-6'>
                        <input
                        type={field === 'licenseExpiration' || field === 'dob' ? 'date' : field === 'email' ? 'email' : 'text'}
                        name={field.replace(/([*])/g, '')}
                        className='form-control'
                        placeholder={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                        onChange={handleChange}
                        value={formData[field.replace(/([*])/g, '')]}
                        />
                    </div>
                    <div className='col-sm-4'></div>
                    </div>
                ))}
                </div>
            </div>
            {/* Vehicle Details Section */}
            <div className='row mt-3'>
                <h3>Vehicle Details</h3>
                <div className='col-12'>
                {['assignedUnit', 'assignedUnitVin', 'assignedUnitClass', 'assignedUnitKilometersOut', 'assignedUnitKilometersIn', 'assignedUnitFuelOut', 'assignedUnitFuelIn'].map((field, index) => (
                    <div className='form-group row pt-2' key={index}>
                    <label htmlFor={field} className='col-sm-2 col-form-label'>{field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                    <div className='col-sm-6'>
                        {field === 'assignedUnit' ? (
                        <select name={field} onChange={handleUnitChange} >
                            <option key="" value="">Select Unit</option>
                            {vinDetail.map((car) => (
                            <option key={car.Unit} value={car.Unit}>
                                {car.Unit}
                            </option>
                            ))}
                        </select>
                        ) : (
                        <input
                            type={field.includes('Date') ? 'date' : 'text'}
                            name={field}
                            className='form-control'
                            placeholder={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                            onChange={handleChange}
                            value={(field === 'assignedUnitVin'?selectedCar.VIN:"" || field === 'assignedUnitClass'?selectedCar.Class:"" ) || formData[field]}
                        />
                        )}
                    </div>
                    <div className='col-sm-4'></div>
                    </div>
                ))}
                </div>
            </div>
            {/* Rental Details Section */}
            <div className='row mt-3'>
                <h3>Rental Details</h3>
                <div className='col-12'>
                {[
                    'locationOut*','locationIn*','dateOut*', 'dateIn*', 'rentalDays*', 'lorAvg', 'timeAndKilometersOnly', 'discount', 
                    'timeKilometersWithDiscount', 'rateCharge*', 'kilometersCharge', 'lateCharge', 
                    'fuelCharge', 'prepaidFuel', 'commerceCityTax', 'stateTax*', 'airportConcession*', 
                    'coRoadSafetyProgram*', 'vehicleLicenseFee*', 'transportationFee*', 'energySurcharge*', 
                    'surcharge*', 'interest', 'batchOther', 'fuelCharges09', 'gps121', 'adminFee128', 
                    'cleanupCharge19', 'downtime20', 'upgrade21', 'deductible23', 'additionalDriverAdd', 
                    'borderCrossingFeeBCF', 'cdw3000', 'cdw3500', 'cdw4000', 'cdw3000Pickup', 
                    'cdw3000Cargo', 'energySurchargeCO', 'coloradoRoadSafetyProgramFee', 
                    'transportationFeeCO', 'vehicleLicensingFeeCO', 'childSeat', 'cdw4000Commercial', 
                    'damage', 'detailFee', 'pickUpDropOffFee', 'governmentAdministrativeRateSupplement', 
                    'gps', 'tollTagIPASS', 'tollTagPP', 'lostKeys', 'sli', 'smokeOdorFee', 
                    'outOfHoursFee', 'oneWayFee', 'outOfState', 'personalAccidentInsurance', 
                    'practicalAssistancePack', 'prepaidGasTank', 'pickupFeeAfterHours', 
                    'prepaidAdjustment', 'roadSideAssistance', 'stateProvinceCrossingFee', 
                    'supplementalLiabilityInsurance', 'tireAndWindshieldCoverage', 'towingCharges', 
                    'ticketViolation', 'tollPass', 'tollViolation', 'tireGlassDamageWaiver', 
                    'underAgeFee', 'extraKmsUnlimited', 'upgrade', 'windshieldChips', 
                    'additionalDriverXDRV', 'underageFeeCharge', 'totalSelectedCharges', 
                    'totalOtherCharges', 'totalCharges', 'dailyDollarAverage', 'adjustment'
                ].map((field, index) => (
                    <div className='form-group row pt-2' key={index}>
                    <label htmlFor={field} className='col-sm-2 col-form-label'>{field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                    <div className='col-sm-6'>
                        {field === 'locationIn*' || field === 'locationOut*' ? (
                            <select name={field.replace(/([*])/g, '')} value={locationIn} onChange={handleUnitChange2} >
                                <option key="" value="">Select Location</option>
                                {locationDetails.map((car) => (
                                <option key={car.key} value={car.value}>
                                    {car.value}
                                </option>
                                ))}
                            </select>
                            ) : (
                            <input
                            type={field === 'dateOut*' || field === 'dateIn*' ? 'datetime-local' : 'number'}
                            name={field.replace(/([*])/g, '')}
                            className='form-control'
                            placeholder={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                            onChange={handleChange}
                            value={formData[field.replace(/([*])/g, '')]}
                            />
                            )
                        }
                    </div>
                    <div className='col-sm-4'></div>
                    </div>
                ))}
                </div>
            </div>
            {/* Buttons Section */}
            <div className='row mt-3'>
                <div className='col-12 d-flex justify-content-center'>
                <button type='submit' className='btn btn-primary m-3'>Save</button>
                <button type="button" className='btn btn-secondary m-3' onClick={handleNewForm}>Clear</button>
                </div>
            </div>
            </form>
        </div>
    );
}